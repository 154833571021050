html {
  scrollbar-color: var(--scroll-color) var(--color-secondary);
  scrollbar-width: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scroll-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-hover-color);
}

.theme-light {
  --color-primary: #fff;
  --color-prime: #eee;
  --color-secondary: #f2f2f2;
  --color-accent: #004256;
  --font-color: #000000;
  --font-color-light: #999;
  --font-color-inverse: #ffffff;
  --border-color: #eee;
  --scroll-color: #d8d7d7;
  --scroll-hover-color: #919191;
  --link-color: #0078d4;
  --icon-color-danger: #f55e5e;
  --icon-color-primary: #004256;
  --icon-color-default: #333;
  --button-danger: #f55e5e;
  --button-primary: #004256;
  --button-secondary: #03a9f4;
  --button-default: #eee;
  transition: 0.4s ease-in-out;
  --rgba: rgba(255, 255, 255, 1);
}

.theme-dark {
  --color-primary: #444450;
  --color-secondary: #2f2f38;
  --color-prime: #222227;

  /* --color-primary: #313131;
  --color-secondary: #252525;
  --color-prime: #000;
  --button-primary:#4a4a4a;
   */

  --color-accent: #004256;
  /* --font-color: #eee; */
  --font-color: #b4b4b4;
  --font-color-light: #999;
  --font-color-inverse: #000;
  --border-color: #4b4b4b;
  --link-color: #86c2f0;
  --scroll-color: #474747;
  --scroll-hover-color: #5e5c5c;
  --icon-color-danger: #f59999fb;
  --icon-color-primary: #04bbf3;
  --icon-color-default: #bdbdbd;
  --button-danger: #f55e5e;
  --button-primary: #004256;
  --button-secondary: #03a9f4;
  --button-default: #eee;
  transition: 0.4s ease-in-out;
  --rgba: rgba(68, 68, 80, 1);
}

table,
tr,
th,
td,
.k-grid-toolbar,
.k-grouping-header,
.ms-DetailsHeader,
.ms-DetailsHeader-cell {
  border-color: var(--border-color) !important;
}

.k-link:hover,
tr.k-master-row:hover {
  color: var(--font-color) !important;
  /* background-color: var(--color-secondary) !important; */
}

#root {
  color: var(--font-color);
  /* background-color: var(--color-secondary); */
}

.grey-bg,
.maindiv {
  color: var(--font-color);
  background-color: var(--color-secondary);
}

/* common */
.theme {
  color: var(--font-color) !important;
  background-color: var(--color-primary) !important;
}

.footerlogo {
  margin: 0;
  padding: 10px 0 20px;
  background-color: var(--color-secondary) !important;
  text-align: center;
}

.atenLogoContainer {
  text-align: center;
  margin-bottom: 33px;
}

.poweredBy {
  color: grey;
  font-size: 10px;
  /* float: left; */
}

.atenLogo {
  width: 140px;
}

.MuiDialogTitle-root,
.MuiDialogContent-root,
.MuiDialogActions-root,
.MuiDialogActions-spacing,
.k-textbox-container,
.MuiPaper-root,
.MuiDrawer-paper,
.dropzone,
[class*='-control'],
[class*='-singleValue'],
[class*='-option']:hover,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-d {
  color: var(--font-color) !important;
  background-color: var(--color-primary) !important;
}

.MuiButton-outlinedPrimary {
  color: var(--button-default) !important;
  background-color: var(--button-primary) !important;
}

.MuiSvgIcon-root {
  color: var(--icon-color-default);
}

.MuiInput-formControl {
  width: 392px !important;
}

.customPreLines {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-top: 0px;
}