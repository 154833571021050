html {
  scrollbar-color: var(--scroll-color) var(--color-secondary);
  scrollbar-width: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scroll-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-hover-color);
}

.theme-light {
  --color-primary: #fff;
  --color-prime: rgb(230, 227, 227);
  --color-secondary: #f2f2f2;
  --color-accent: #01244E;
  /* --color-accent: #004256; */
  --font-color: #000000;
  --font-color-light: #999;
  --font-color-inverse: #ffffff;
  --border-color: #C0C0C0 !important;
  --scroll-color: #d8d7d7;
  --scroll-hover-color: #919191;
  --link-color: #0078d4;
  --icon-color-danger: #f55e5e;
  --icon-color-primary: #004256;
  --icon-color-default: #333;
  --button-danger: #f55e5e;
  --button-primary: #01244E;
  --button-default: #eee;
  transition: 0.4s ease-in-out;
  --rgba: rgba(255, 255, 255, 1);
  --rgba-chart: rgba(0, 0, 0, 1);
}

.dx-datagrid-rowsview .dx-row {
  border-top: 1px solid transparent !important;
}

.theme-dark {
  --color-primary: #444450;
  --color-secondary: #2f2f38;
  --color-prime: #222227;

  /* --color-primary: #313131;
  --color-secondary: #252525;
  --color-prime: #000;
  --button-primary:#4a4a4a;
   */

  --color-accent: #01244E;
  /* --font-color: #eee; */
  --font-color: #b4b4b4;
  --font-color-light: #999;
  --font-color-inverse: #000;
  --border-color: #C0C0C0 !important;
  --link-color: #86c2f0;
  --scroll-color: #474747;
  --scroll-hover-color: #5e5c5c;
  --icon-color-danger: #f59999fb;
  --icon-color-primary: #04bbf3;
  --icon-color-default: #bdbdbd;
  --button-danger: #f55e5e;
  --button-primary: #0078d4;
  --button-default: #eee;
  transition: 0.4s ease-in-out;
  --rgba: rgba(68, 68, 80, 1);
  --rgba-chart: rgba(255, 255, 255, .87);
}

table,
tr,
th,
td,
.k-grid-toolbar,
.k-grouping-header,
.ms-DetailsHeader,
.ms-DetailsHeader-cell {
  border-color: var(--border-color) !important;
}

.k-link:hover,
tr.k-master-row:hover {
  color: var(--font-color) !important;
  /* background-color: var(--color-secondary) !important; */
}

#root {
  color: var(--font-color);
  /* background-color: var(--color-secondary); */
}

.grey-bg,
.dx-accordion {
  color: var(--font-color) !important;
  background-color: var(--color-secondary) !important;
}

/* common */
.theme {
  color: var(--font-color) !important;
  background-color: var(--color-primary) !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: var(--color-prime) !important;
}

.k-grid-content,
.k-grid-footer-wrap,
.k-grid-header-wrap,
.k-toolbar,
.k-grid-toolbar,
.k-filter-row,
.k-select,
.k-pager-wrap,
.k-pager,
.k-grid-pager,
.k-grid-content-sticky,
.k-grid-header-sticky,
.k-indicator-container,
.MuiDialogTitle-root,
.MuiDialogContent-root,
.MuiDialogActions-root,
.MuiDialogActions-spacing,
.k-textbox-container,
.MuiPaper-root,
.MuiDrawer-paper,
.k-splitter,
.k-calendar .k-calendar-navigation,
.k-calendar-header,
.k-calendar-view,
.k-calendar-monthview,
.k-content,
.ms-DetailsHeader,
.ms-DetailsHeader-cell,
.ms-DetailsRow-fields,
.ms-Panel-contentInner,
.k-textarea,
.k-textarea:hover,
.dropzone,
.k-datetime-buttongroup,
.k-datetime-selector,
.k-time-list-wrapper,
[class*='-control'],
[class*='-singleValue'],
[class*='-option']:hover,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-d {
  color: var(--font-color) !important;
  background-color: var(--color-primary) !important;
}

.gm-style .gm-style-iw-c::after {
  background: linear-gradient(45deg, var(--rgba) 50%, var(--rgba) 51%, var(--rgba) 100%) !important;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.k-tabstrip>.k-content,
[class*='-menu'] {
  background-color: transparent !important;
}

.k-group-indicator,
.k-state-selected,
.ant-popover-inner,
.ant-popover-arrow,
[class*='-option'],
[class*='-option']:active {
  color: var(--font-color) !important;
  background-color: var(--color-secondary) !important;
}

.MuiDivider-root,
.MuiAccordionSummary-root,
.MuiButton-contained {
  color: var(--font-color) !important;
  background-color: var(--color-prime) !important;

}



.MuiButton-outlinedPrimary,
.dx-checkbox-checked .dx-checkbox-icon,
.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection,
.dx-fa-button.dx-fa-button-main {
  color: var(--button-default) !important;
  background-color: var(--button-primary) !important;
}

.dx-scheduler-header .dx-toolbar .dx-button .dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid-filter-panel .dx-icon-filter,
.dx-icon {
  color: var(--button-primary) !important;
}

.dx-button.dx-button-default {
  background-color: var(--button-primary) !important;
  color: var(--button-default) !important;
  border: 1px solid var(--button-primary) !important;
}

.dx-button-mode-outlined.dx-button-default {
  background-color: transparent !important;
  color: var(--button-primary) !important;
  border: 1px solid var(--button-primary) !important;
}

.k-icon,
.k-reset,
.header2-text,
.k-tabstrip-items,
.k-link,
th,
.ms-Panel-navigation button,
.ms-Panel-headerText,
.k-title {
  color: var(--font-color) !important;
}

.k-i-gear,
.k-fab-icon {
  color: var(--font-color-inverse) !important;
}

.k-textbox,
.k-combobox,
.k-dropdown-wrap,
.k-picker-wrap,
.k-state-default,
.k-multiselect .k-multiselect-wrap,
.k-tabstrip-items,
.k-checkbox,
.dropzone,
.k-textarea {
  border-color: var(--font-color) !important;

}

.k-multiselect .k-multiselect-wrap,
.k-textbox,
.k-multiselect-wrap .k-floatwrap {
  border-top: none !important;
}

.ant-popover-arrow {
  border-color: var(--color-secondary) !important;

}

/* .k-textbox-container,.k-searchbar,
label, input,.k-input,.k-select{
  color:var(--font-color) !important;
  background-color: transparent !important;
} */
/* .k-checkbox{
} */
input[type=checkbox]:checked {
  color: #f2f2f2 !important;
  background-color: #036a8a !important;
}

::placeholder {
  color: var(--font-color-light) !important;

}

.fill-available {
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.k-grid-content-sticky {
  border-left: 1px solid #000;
}

.k-calendar-infinite .k-calendar-view::after,
.k-calendar-infinite .k-calendar-monthview::after,
.k-calendar-navigation::before,
.k-calendar-navigation::after,
.k-time-list::before,
.k-time-list::after {
  box-shadow: 0 0 2.8571428571em 1.4285714286em var(--color-prime) !important;
}

.font {
  color: var(--font-color) !important;
}

.fontlight {

  color: var(--font-color-light) !important;
}

.fontInverse {

  color: var(--font-color-inverse) !important;
}

button {
  color: var(--font-color) !important;
  border-color: var(--font-color) !important;
}

.danger {
  color: var(--icon-color-danger) !important;
  margin-top: -7px !important;
}

.MuiPaper-root.assetSelectPaper .danger {
  margin-top: 0px !important;
  cursor: pointer;
}

.primary {
  color: var(--icon-color-primary) !important;
}

.default {
  color: var(--icon-color-default) !important;
}

.navIcon {
  color: var(--button-default) !important;
}

.navIconMobile {
  color: var(--font-color) !important;
}

/* .notificationContent{
  min-height: 100px;
  max-height: 400px;
  overflow: auto;
} */
.notificationHeading {
  background-color: #0078d4 !important;
  color: #fff !important;
  padding: 13px;
  font-size: 18px;
}

.MuiSvgIcon-root {
  color: var(--icon-color-default);
  font-size: 1.5rem !important;
}

.popContentDiv {
  margin: 10px;
}

.k-animation-container-shown,
.k-animation-container>.k-popup {

  z-index: 9999999 !important
}

.k-animation-container-relative {
  display: block !important;
}

.dashboardHome {
  background-color: var(--color-secondary);
  min-height: 100vh;
  margin-top: 10px;
}

.chart {
  margin: 10px;
  border-radius: 4px !important;
}

.chartPaper {
  margin: 10px;
  border-radius: 4px !important;
}

.chartName {
  padding: 14px 0 7px 10px;
  white-space: pre;
  font-family: Roboto, RobotoFallback, Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: var(--rgba-chart) !important;
  cursor: default;
}

.woToatalChart {
  min-height: 310px;
  margin-top: 15px;
}

.workorderNumber {
  padding-top: 9%;
  text-align: center;
  font-size: 11.2vw;
  color: var(--icon-color-primary) !important;
}

.dashboardFilterDiv {
  color: rgb(0, 68, 89) !important;
  display: flex;
}

/* .gm-style .gm-style-iw-c{
  color:var(--font-color) !important;
  background-color: var(--color-primary) !important;
} */
.infoWIndowBox {

  color: #333;
}

.infoWindowWODetail {
  width: 100px;
  display: inline-block;
  margin-bottom: 4px;
}

.gm-style .gm-style-iw-t {
  background-color: var(--color-secondary) !important;
}

.autocompletSearchBar {
  margin: 0 auto;
  width: 97%;
  border-radius: 10px;
  border: 1px solid var(--font-color) !important;
  margin-top: 10px;
  background-color: var(--color-primary) !important;
  /* color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; */
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
}

.autocompletDiv {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  /* border: 1px solid #605e5c; */
  /* background: #ffffff; */
  /* height: 32px; */
  /* display: -webkit-box;
    display: -ms-flexbox;
    display: flex; */
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
}

.autocompletSearchBox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none;
  background-color: transparent;
  padding: 0 12px 0 12px;
  width: 100%;
  min-width: 0;
  text-overflow: ellipsis;
  outline: 0;
  height: 45px;
  width: 95%;
  font-size: 16px;
  outline: none;
  margin-left: 20px;
  color: #000;

}

.autocompleteDropdownContainer {
  /* margin:0 auto; */
  margin-left: 1.5%;
  width: 97%;
  background-color: var(--color-primary) !important;
  position: absolute;
  z-index: 1;
  box-shadow: 1px 13px 13px 0 rgba(0, 0, 0, .2);
}

.suggestion-item--active {
  background-color: var(--color-secondary) !important;
  padding: 15px;
}

.suggestion-item {

  background-color: var(--color-primary) !important;
  padding: 15px;
}

.filterOptionDiv {
  margin: 10px 25px;
}

.filterOptionIcon {
  position: relative;
  top: 7px;
  border-radius: 50%;
  background: #eee;
  padding: 13px;
}

canvas {
  border: none !important;
}

.outer-pane {
  fill: var(--color-primary) !important;
  stroke: none !important;
}

.middle-pane {
  stroke-width: 1px !important;
  stroke: rgb(240, 221, 221) !important;
}

.inner-pane {
  fill: #ddd !important;
  stroke: none !important;
}

.second-pane {
  fill: var(--color-primary) !important;
  stroke: none !important;
}

.plotBand {
  fill: #ff0000 !important;
  stroke: none !important;
}

.platBandNum {
  color: var(--font-color) !important;
}

/* Navigation style */
.MuiListItem-button:hover {
  background-color: var(--color-prime) !important;
}

.drawerTooltip {
  background-color: var(--color-prime) !important;
  color: var(--font-color) !important;
  padding: 5px !important;
  min-width: 150px;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em
}

.NavIcon {
  margin-left: 8px;
}

.maindiv {
  /* margin-top: -18px; */
  height: 100vh;

}

.makeStyles-search-20 {
  background-color: #ffffff;
}

.searchResultDiv {
  /* min-height: 100px; */
  position: absolute;
  z-index: 100;
  min-width: 100px;
  box-shadow: 1px 13px 13px 0 rgba(0, 0, 0, .2);
  z-index: 1;
  width: 100%;
  /* border-bottom: 2px solid #004256; */
}

.searchResultDrop {
  color: var(--font-color) !important;
  background-color: var(--color-primary) !important;
}

.searchDropHeading {
  font-size: 18px;
  margin-bottom: 10px;
}

.searchDropContent {
  margin: 10px;
}

.searchDropList:hover {
  background-color: var(--color-secondary) !important;
}

.searchDropList {
  padding: 10px;
  border-bottom: 1px solid var(--color-prime) !important;
}

.searchPageHeading {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}

.searchPageContent {
  margin: 10px;
  padding-bottom: 10px;
}

/* .searchPageList:hover{
  background-color: var(--color-prime) !important;
} */
.searchPageList {
  padding: 10px;
  border-bottom: 1px solid var(--color-prime) !important;
  margin-bottom: 10px;
}

.searchPageDescription {
  color: var(--font-color-light);

  margin-top: 5px;
  line-height: 21px;
  min-height: 40px;
  max-height: 58px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.noResultFoundDiv {
  text-align: center;
  margin-top: 30px;
}

.noResultFoundTitle {
  font-size: 24px;
  font-weight: 500;

}

.searchBarIcon {
  position: relative;
  top: 5px;
  left: 10px;
  float: right;
  margin-right: 15px;
  font-size: 1.2em !important;
}

a {
  text-decoration: none !important;
  color: var(--font-color) !important;
  cursor: pointer;
}

.searchButton {
  /* background-color: #78f75e !important; */
  background-color: var(--color-secondary) !important;
  color: #fff !important;
}

.selectionDiv {
  background-color: var(--color-primary);
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.justifyContentCenter {
  justify-content: center;
}

.alignCenter {
  text-align: center;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.borderBottom {
  border-bottom: 1px solid var(--font-color-light) !important;
}

.borderLeft {
  border-left: 1px solid var(--font-color) !important;
}

.selectedRowDiv {
  margin-top: 10px;
}

.checkboxInput {
  margin-top: 15px !important;
}

.headingDiv {

  color: var(--font-color);
  display: flex;
  margin-bottom: 13px;
}

.logowo {
  width: 50px;
  height: 50px;
}

.logoDiv {
  display: flex;
}

.signin {
  background-color: #3b6ca1 !important;
}

.appBar {
  /* MuiAppBar-colorPrimary */
  /* background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 121, 14, 1) 59%, rgba(0, 212, 255, 1) 100%);
   */
  /* background-color:var(--color-accent) !important;*/
  background-color: #133355 !important
}

.appBarAdmin {
  background-color: #0078d4 !important;

}

.is-active>div {
  background-color: var(--color-secondary);
  color: var(--font-color);
}

.is-active {
  background-color: #eee;
}

.is-activeNav {
  background-color: #056683;
  color: white;
}

/* General style */


.formControl {
  min-width: 100% !important;
}

.MuiInput-formControl {
  width: 392px !important;
}

/* .k-animation-container-shown, .k-animation-container > .k-popup, */
.k-list-scroller {
  z-index: 999999 !important;
  /* position: absolute !important; */
  background-color: var(--color-primary) !important;
  /* width: inherit !important; */
}

.k-textbox-container {
  width: 100% !important;
}

.selectEmpty {
  margin-top: 10px !important;
}

.multiSelect {
  width: 100% !important;
}

.SearchDiv {
  margin: 100px 15px 15px 20px !important;
  /* background-color: var(--color-primary); */
  color: var(--font-color);
}

.subMenuLinkIcon {
  font-size: 24px !important;
  color: var(--font-color) !important;
  padding-right: 5px;
}

.tabHeading {
  text-transform: capitalize !important;
  display: flex;
}

/* scheduler */

.department {
  border-radius: 27px;
  padding: 5px 10px 5px;
  border: 0.5px solid pink;
  margin: 5px;
  display: inline-block;
  background-color: var(--color-primary) !important;
  color: var(--font-color) !important;
  cursor: pointer;
}

.departmentList::-webkit-scrollbar {
  display: none;
}

.department:hover {
  background-color: rgb(189, 189, 189);
  /* border: 2px solid #333; */
  color: #000;
}

.selectedDept {
  background-color: var(--color-prime) !important;
  font-weight: 500;
}

.department-wrapper {
  position: relative;
  max-width: 90vw;

  overflow-x: hidden;
  overflow-y: hidden;
}

.departmentList {

  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding-left: 5px;
}

.paddle {
  position: absolute;
  top: 14px;
  bottom: 0;
  cursor: pointer;
}

.left-paddle {
  left: 0;
  padding: 10px 30px 10px 10px;
  background: linear-gradient(90deg, var(--color-secondary), transparent);
}

.right-paddle {
  right: 0;
  padding: 10px 10px 10px 30px;
  background: linear-gradient(90deg, transparent, var(--color-secondary));
}

.hidden {
  display: none;
}

.searchFieldDiv {
  margin: 0 20px;
}

.tabsDiv {
  margin: 0 20px;
}

.schedulerDiv {
  margin: 0 20px;
}

.searchScheduleFieldDiv {
  margin: 20px 20px 10px;
}

.legendText {
  margin-left: 38px;
  margin-top: 12px;
  margin-bottom: 0px;
}

.legendColor {
  width: 30px;
  height: 16px;
  float: left;
  margin-bottom: 0px;
}

.legendContrastColor {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-filter: invert() sepia() drop-shadow(2px 4px 6px black);
  filter: invert() sepia() drop-shadow(2px 4px 6px black);
  font-weight: bold;
}

.boldTitle {
  font-weight: 600;
}

.ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: var(--font-color-light) !important;
}

.ant-fullcalendar-value,
.ant-select-dropdown-menu-item,
.anticon,
.ant-fullcalendar-column-header {
  color: var(--font-color) !important;
}

.ant-select-dropdown-menu-item {
  overflow: visible !important;

}

.ant-col {
  margin-left: 59px !important;
}

.ant-select-dropdown,
.ant-select-selection,
.ant-select-sm .ant-select-selection__rendered {
  background-color: var(--color-primary) !important;
  color: var(--font-color) !important;
}

.ant-select-dropdown-menu-item-selected {
  background-color: var(--color-prime) !important;
}

.detailFieldDiv {

  margin: 10px 20px;
  background-color: #fff !important;
}




.searchButtonDiv {
  float: right;
  margin: 20px;
}

.naturalSearchButtonDiv {
  margin: 20px;

}

.TextField {
  width: 100% !important;
  border-top: none !important;
}

.filterWOSearchBarDiv {
  margin: 0 8px;

  border-radius: 5px;
  border: 1px solid var(--color-prime) !important;

  background-color: var(--color-secondary) !important;
  color: var(--font-color) !important;
  display: flex;
}

.filterWOsearchbar {
  height: 45px;
  border: none;
  border-radius: 30px;
  width: 99%;
  font-size: 16px;
  outline: none;
  margin-left: 20px;
  color: var(--font-color) !important;
}

/* Asset Search style */
.naturalSearchBar {
  margin: 0 auto;
  width: 70%;
  border-radius: 30px;
  border: 1px solid var(--color-prime) !important;
  margin-top: 30px;
  background-color: var(--color-primary) !important;
  color: #000;
  display: flex;
}

.naturalSearchBar:hover {
  box-shadow: 1px 1px 8px 1px var(--color-prime) !important;
}

.naturalSearchBar:focus-within {
  box-shadow: 1px 1px 8px 1px var(--color-prime) !important;
  outline: none;
}

.voice {
  float: right;
  margin: 14px;
  /* height:20px;
  position:relative;
  top:5px;
  left:10px; */
}

.assetSearchButton {
  font-weight: 700 !important;
  padding: 9px 33% !important;
  cursor: pointer;
  align-items: center !important;
  border: 1px solid var(--font-color) !important;
  border-radius: 4px;
}

.assetSearch {
  align-items: center !important;
}

.assetSearchButton:hover {
  background-color: var(--color-primary) !important;
  color: var(--font-color) !important;
}

.buttonDivLeft {
  float: right !important;

}

.dateField {
  width: 100%;
}

.content {
  flex-grow: 1 !important;
  background-color: var(--color-secondary);
  /* padding: 15px !important; */
}

.displayFlex {
  display: flex;
}

.center {
  text-align: center !important;
}

.alertLoader {
  text-align: center;
  width: 250px;
}

.WODetailTab>ul {
  font-weight: bold !important;
  background-color: #83f06d46 !important;
  color: #004256;
  text-transform: capitalize !important;
}

.WODetailTab>ul>li.k-state-active>.k-link {
  color: #fff !important;
}

.ScheduleWODetailTab>ul {
  font-weight: bold !important;
  background-color: var(--color-primary) !important;
  color: #004256;
  text-transform: capitalize !important;
}

.ScheduleWODetailTab>ul>li.k-state-active>.k-link {
  color: #fff !important;
}

.tabLink>ul>li.k-state-active>.k-link {
  color: #fff !important;
}

.woDetailIcon {
  color: #fff !important;
  cursor: pointer;
  background-color: var(--color-accent) !important;
  margin: 2px;
  padding: 5px;
}

.woDetailIcon:hover {
  color: var(--font-color) !important;
  background-color: var(--color-prime) !important;
}

.semiBold {
  font-weight: bold;
}

.k-tabstrip>.k-content {
  padding: 0 !important;
}

.k-grid-header,
.k-grid-footer {
  padding: 0 !important;
}

.k-grid-content {
  overflow-y: auto !important;
}

li.k-state-active {

  background-color: var(--color-accent) !important;
  color: #83f06d !important;
}

.iframStyle {
  width: 100% !important;
  height: 250px !important;
  border: none !important;
  overflow: hidden !important;
  /* pointer-events: none !important; */
}

/* .addEditModal{
  width: 85%;
  height: 100%;
} */

/* search workoder style */
.searchWOPanelheader {
  background-color: var(--color-prime) !important;
  color: var(--font-color) !important;
  font-weight: 500;
  font-size: 1.3em;
}

.searchWOPanel {
  border: none !important;
  background-color: var(--color-secondary) !important;
}

.searchWOPanelItem {
  border-radius: 7px;
  margin-bottom: 15px;
  box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.23);
}

.k-panelbar>.k-item+.k-item {
  border: none !important;
}

/* admin style */

.adminBoxDiv {
  margin: 100px 15px 15px 20px !important;
}

.adminPaperDiv {
  display: flex;
  flex-wrap: wrap;
}

.adminPaper {
  text-align: center;
  cursor: pointer;
  margin: 10px;
  width: 160px;
  padding: 20px;
}

.tpcimage {
  height: 300px;
}

.modelCell {
  overflow: hidden !important;
  white-space: nowrap;
}

/* div.pac-container {
  z-index: 99999999999 !important;
} */

.pac-container {
  z-index: 99999999999 !important;
}

​

/* profile */
.ProfileCard {
  margin: auto !important;
  text-align: center !important;
  justify-content: center;
  /* margin: 10px; */
  padding: 10px;
  min-height: 300px;
}

.loginInnerDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 3px 18px 6px rgba(0, 0, 0, 0.23);
  border-radius: 20px;
  padding: 33px;
  /* width: 25%; */
}

.signInFieldError {
  color: rgb(241, 26, 26);
  font-size: 11px;
}

/* Landing page */
.LPHeroTop {
  min-height: 92vh;
  background-color: #fff;
}

.LPHeroContentTop {
  margin: 30% auto;
  text-align: center;
  align-items: center;
  width: 70%;
  color: #333;
}

.LPHeroContentTopTitle {
  font-size: 3.3em;
}

.LPHeroImgDivTop {
  margin-top: 30%;
  text-align: center;

}

.CWLogoHome {
  width: 50%;
}

.LPHeroImgTop {
  /* height: 61vh; */
  width: 88%;
  box-shadow: 0px 3px 18px 6px rgba(0, 0, 0, 0.23);
}

/* bottom part */
.LPHero {
  min-height: 88vh;
  background-color: #eee;
}

.LPHeroContent {
  margin: 20% auto;
  text-align: center;
  align-items: center;
  width: 70%;
  color: #333;
}

.LPHeroImgDiv {
  margin-top: 12%
}

.LPHeroImg {
  /* height: 61vh; */
  width: 100%;
}

.LPInfoContent1 {
  height: 70vh;
  background-color: #252525;
  color: #eee;
}

.LPInfoContent2 {
  min-height: 60vh;
  margin-top: 21%;
  margin-left: 7%;
  margin-right: 7%;
  margin-bottom: 5%;
  color: #333;
  text-align: center;
}

.LPInfoContent3 {

  min-height: 60vh;
  padding: 5% 7%;
  color: #333;
  text-align: center;
  background-color: #fff;
}

.videoIframe {
  cursor: pointer;
  height: 80vh;
  width: 86%;
  text-align: center;
  margin: auto;
  background-color: black;
  outline: none;
  border: 1px solid;
}

.LPInfoHeading {
  text-align: center;
  margin: auto;
  align-items: center;
  padding: 40px;
  width: 70%;
}

.LPMenuItem {
  padding: 15px;
  color: #fff;
}

.LPMenuItem:hover {
  background-color: #0078d4;
}

.subscriptionBox {
  min-height: 350px;
  background-color: #fff;
  color: #004256;
  margin: 6px 4px 19px 10px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 4px 5px 12px #ccc;
  border: 1px solid #fff;
  /* transition-delay: .5s; */

  transition: 0.4s ease-in-out;
}

.subscriptionHeader {
  border-bottom: 1px solid #adadad;
}

.subscriptionContent {
  height: 180px;
  text-align: center;
  margin: 26px 10px;
}

.subscriptionContentIconGreen {
  color: #048821;
}

.subscriptionContentIconRed {

  color: #f51a1a;
}

.subscriptionContentInfo {
  padding: 10px;
  font-size: 18px;
}

.subscriptionBtn {
  color: #000 !important;
}

.subscriptionAction {
  padding-top: 6px;
  /* border-top: 1px solid #adadad; */
}

.subscriptionBox:hover {
  /* -moz-box-shadow: inset 10px 10px 10px rgba(6, 24, 29, 0.5);
  -webkit-box-shadow: inset 10px 10px 10px rgba(6, 24, 29, 0.5);
  box-shadow: inset 12px 12px 12px rgba(6, 24, 29, 0.5); */
  /* box-shadow: inset -10px -10px 20px #fff, inset 8px 8px 20px #ccc */
  -moz-box-shadow: 1px 1px 1px#adadad;
  -webkit-box-shadow: 1px 1px 1px #adadad;
  box-shadow: 1px 1px 2px #adadad;
}

.FAQHome {
  margin-top: 50px;
  background-color: #f2f2f2;
  /* background-image: url("../images/FAQBanner.png"); */
  /* background-color: #cccccc;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; */
}

.faqAns {
  margin: 30px;
}

.FAQPanel {
  margin: 50px 10px 50px 20px;
}

.FAQHeroImgDiv {
  margin-top: 6% !important;
}

.FAQHeroImg {
  width: 100%;
}

.FAQContent {
  background-color: white;
}

.FAQContact {
  box-shadow: 4px 5px 12px #ccc;
  min-height: 300px;
  margin: 50px 10px 50px 20px;
  padding: 15px;
  color: #333 !important;
}

.accordionHeading {
  font-size: 18px;
  font-weight: bold;

}


.AccordianItem {
  box-shadow: 4px 5px 12px #ccc;
  margin-bottom: 10px;

}

.AccordianFAQHeading {
  background-color: #fff;
  cursor: pointer;
  padding: 15px;
  font-size: 17px;
  font-weight: 600;
}

.AccordianFAQBtn {
  cursor: pointer;
  outline: none;
}

.AccordianFAQPanel {
  padding: 10px;
  color: rgb(110, 110, 110);
}

.accordion__button {
  color: #444;
  border: none;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

.comingSoon {
  text-align: center;
  margin-top: 15%;
}

.comingSoonText {
  color: var(--font-color-light) !important;
  font-size: 7em;
}

.ContactHome {
  margin-top: 50px;
  /* background-color: #f2f2f2; */
  background: linear-gradient(#f2f2f2, #fff);
  height: 60vh;
  color: #444;
}

.ContactContent {
  background-color: white;
  height: 60vh;
}

.ContactForm {
  box-shadow: 4px 5px 12px #ccc;
  background-color: white;
  min-height: 300px;
  margin: 55px 33px 50px 20px;
  padding: 32px;
  color: #333 !important;
}

.ContactHeading {
  text-align: center;
  margin-top: 50px;
}

.ContactHeroContent {
  margin: auto;
  text-align: center;
  align-items: center;
  width: 70%;
  color: #333;
}

.ContactInfo {
  margin: 10% 25%;
  align-items: center;
  color: #444;
}

.SupportHome {
  margin-top: 60px;
  background-color: #f2f2f2;
  /* background:linear-gradient(#b9b9b9, #fff); */
  height: 370px;
  color: #444;
}

.SupportContent {
  background-color: white;
  padding: 20px;
  color: #333;
  /* height: 40vh; */
}

.SupportLoginBtn {
  text-align: center;
  margin-top: 10px;
}

.SupportHeroContent {
  margin: 55px auto;
  text-align: center;
  align-items: center;
  width: 70%;
  color: #333;

}

.supportNavBox {
  min-height: 150px;
  background-color: #fff;
  color: #004256;
  margin: 6px 4px 19px 10px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 4px 5px 12px #ccc;
  border: 1px solid #fff;
  /* transition-delay: .5s; */

  transition: 0.4s ease-in-out;
}

.supportNavBox:hover {
  /* -moz-box-shadow: inset 10px 10px 10px rgba(6, 24, 29, 0.5);
  -webkit-box-shadow: inset 10px 10px 10px rgba(6, 24, 29, 0.5);
  box-shadow: inset 12px 12px 12px rgba(6, 24, 29, 0.5); */
  /* box-shadow: inset -10px -10px 20px #fff, inset 8px 8px 20px #ccc */
  -moz-box-shadow: 1px 1px 1px#adadad;
  -webkit-box-shadow: 1px 1px 1px #adadad;
  box-shadow: 1px 1px 2px #adadad;
}

.supportIcon {
  font-size: 58px !important;
  color: #004256;
  padding: 7px !important;
}

.supportIconDiv {
  margin: auto !important;
  text-align: center !important;
}

/* exportbutton */
.exportCSVButton {
  cursor: pointer;
  vertical-align: middle;
  margin: -34px 5px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-top-color: rgba(0, 0, 0, 0.23);
  border-right-color: rgba(0, 0, 0, 0.23);
  border-bottom-color: rgba(0, 0, 0, 0.23);
  border-left-color: rgba(0, 0, 0, 0.23);
  padding: 4px 15px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  background-color: var(--color-secondary) !important;
  color: var(--font-color) !important;
}


/* form Builer CSS */
.FBMenuItem {
  padding: 15px;
  color: var(--color-primary) !important;
}

.FBMenuItem:hover {
  background-color: #0078d4;
}

.span {
  font-size: 11px;
}

.label {

  font-size: 11px !important;
}

.formCard {
  border-radius: 10px;
  background: linear-gradient(#008272, #056683);
}

.homeHeading {
  margin-top: 23px;
  font-size: 30px;
}

.homeHeadingDiv {

  margin-top: 75px;
}

.homeFormsDiv {
  margin: 0 50px 50px;
}

.homeFormsHeadingDiv {
  margin: 0 50px;
}

.cardHeading {
  font-size: 30px;
  font-weight: 600;
  color: var(--color-primary) !important;
  padding: 15px 11px 11px;
  line-height: 34px;
  min-height: 40px;
  max-height: 58px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cardHeadingDiv {
  height: 100px;
}

.cardInfo {
  height: 50px;
  color: var(--color-primary) !important;
  margin-left: 10px;
  font-size: 13px;
}

.linksSection {
  height: 45px;
}

.cardIcon {
  color: var(--font-color-inverse) !important;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  margin-right: 5px;
  float: right;
}

.cardIcon:hover {
  background-color: var(--color-primary) !important;
  color: var(--font-color) !important;
}

.cardDeleteIcon:hover {

  color: rgb(59, 3, 3) !important;
  background-color: rgb(255, 17, 17) !important;
  cursor: pointer;
}

.createFormDiv {
  margin: 40px 20px 20px;
}

.editFieldBorder {
  /* border-left: 3px solid #004459; */
  border-top: 4px solid #484644;
}

.editFieldContainer {
  /* background-color: #F4F4F4; */
  padding: 10px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-primary) !important;
  border-radius: 5px;
  margin: 10px;
}

.fieldBlock {
  margin-bottom: 15px;
}

.builder {
  width: 100%;
  margin: 0 auto;
  /* background: var(--color-secondary); */
  /* box-shadow: 0 1px 2px 0 rgba(0,0,0,.1); */
  min-height: 300px;
}

.builderContent {
  /* background-color: var(--color-secondary); */
  margin: 10px 4px 0 4px;
}

.builderHeader {
  min-height: 84px;
  display: flex;
  width: 100%;
  flex-direction: row;
}

.builderHeadingText {
  padding-top: 22px;
  padding-left: 15px;
  color: var(--font-color) !important;
  font-size: 27px;
  font-weight: bold;
}

.builderHeadingIcon {
  padding-top: 31px;
  padding-left: 15px;
}

.builderDivHRLine {
  border: 1px solid #eee !important;
}

.fieldHeadingRow {
  height: 40px;
  background: rgb(0, 130, 114);
  margin-top: -10px !important;
  margin-left: -10px;
  margin-right: -10px;
  border-radius: 5px;
}

.fieldHeadingRowSection {
  height: 40px;
  background: rgb(0, 128, 74);
  margin-top: -10px !important;
  margin-left: -10px;
  margin-right: -10px;
  border-radius: 5px;
}

.fieldHeadingRowParent {
  height: 40px;
  background: rgb(0, 68, 86);
  margin-top: -10px !important;
  margin-left: -10px;
  margin-right: -10px;
  border-radius: 5px;
}

.fieldHeadingRowSelectedForCondition {
  background-color: #777505 !important;
}

.fieldTypeDescription {
  padding-top: 10px;
  font-size: 20px;
  color: white !important;
  font-weight: 500;
  margin-top: -9px;
  padding-left: 10px;
}

.formpaper {
  border-radius: 5px !important;
  cursor: move;
  box-shadow: 2px 2px 9px 0px rgb(0, 0, 0, 0.5) !important
}

.hand {
  cursor: pointer;
}

.dragCursor {
  cursor: move
}

.stopCursor {
  cursor: no-drop;
}

.editFieldIcons {
  font-size: 20px;
  margin-top: 5px;
  color: white;
}

.editDateIcon {
  float: right;
  margin-top: 2px !important;
  font-size: 16px;
}

.lineBreak {
  border: 4px solid #333;
  width: 100%;
  margin-top: 20px;
}

.grey {
  color: #999898;
}

.greyish {

  background-color: var(--color-primary);
}

.red {
  color: rgb(247, 165, 165) !important;
}

.chocieOptionIconDiv {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  /* margin-left: 5px; */
}

.chocieOptionIcons {
  font-size: 15px;
  margin-left: 10px;
  color: #777777;
}

.createBtn {

  padding: 13px 21px;
  border: 1px solid rgb(241, 99, 99);
  border-radius: 28px;
  background-color: rgb(255, 142, 142);

  box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.4);
  font-weight: 555;
  cursor: pointer;
  color: #333;
}

.createBtn:hover {
  background-color: rgb(241, 99, 99);
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.4);
}

.homeFormButton {
  margin-top: 20px;

}

.homeformSearchBar {
  margin: 20px;

  border-radius: 30px;
  border: 1px solid #707070 !important;

  background-color: var(--color-primary) !important;
  color: var(--font-color) !important;
}

.sectionSearchBar {
  margin: 10px auto auto;
  /* width:80%; */
  border-radius: 30px;
  border: 1px solid var(--color-primary) !important;
  /* margin-top: 30px; */
  background-color: var(--color-secondary) !important;
  color: var(--font-color) !important;
  display: flex;
}

.sectionTitle {
  font-size: 20px;
  padding: 14px 14px 0;
  text-align: center;
  color: var(--font-color) !important;

}

.sectionSubTitle {
  /* font-size: 20px; */
  padding: 0;
  text-align: center;
  color: #888787;
}

.searchbar {
  height: 49px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  outline: none;
  margin-left: 20px;
  color: var(--font-color) !important;
  width: 99%;
  background-color: var(--color-primary) !important;
}

.addressSearchBar {
  margin: 9px auto;
  width: 100%;
  /* border-radius:30px; */
  border: 1px solid var(--border-color) !important;
  background-color: var(--color-secondary) !important;
  color: #000;
  display: flex;
  height: 48px;
}

.formSearchbar {
  height: 45px;
  border: none;
  /* width:86%; */
  font-size: 16px;
  outline: none;
  margin-left: 20px;
  color: var(--font-color) !important;

  background-color: var(--color-primary) !important;
}

.searchIcon {
  float: right;
  margin: 14px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.disableInput {
  margin: 0 auto;
  width: 100%;
  border: 1px solid #eee !important;
  /* margin-top: 30px; */
  background-color: var(--color-secondary) !important;
  color: var(--font-color) !important;
}

.disableInputBar {
  border: none;
  font-size: 12px;
  outline: none;
  margin: 5px;
  color: rgb(100, 100, 100);
  background-color: var(--color-secondary);
}

.disabledIcon {
  float: right;
  margin: 7px;
}

.updateFormInputBar {
  margin: 0 auto;
  width: 97%;
  border-radius: 5px;
  border: 1px solid rgb(131, 130, 130) !important;
  /* margin-top: 30px; */
  background-color: var(--color-primary) !important;
  color: var(--font-color) !important;
  margin-top: 33px;
}

.createFormInputBar {
  margin: 0 auto;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(131, 130, 130) !important;
  /* margin-top: 30px; */
  background-color: var(--color-primary) !important;
  color: var(--font-color) !important;
}

.FormInputBar {
  height: 45px;
  border: none;

  font-size: 16px;
  outline: none;
  margin-left: 20px;
  color: var(--font-color) !important;
  background-color: var(--color-primary) !important;
}

/* segment */
.Fault {
  background-color: rgb(238, 120, 120) !important;
  color: var(--color-primary) !important;
}

.OK {
  background-color: rgb(3, 206, 121) !important;
  color: var(--color-primary) !important;
}

/* parent section */
.parentHeading {
  font-size: 20px;
  font-weight: 600;
  margin: 30px 10px;
}

.parentItems {
  font-size: 16px;
  font-weight: 500;
  margin: 10px;
  border: 1px solid #575656;
  padding: 10px;
}

.selectedParentItems {
  background-color: rgb(0, 121, 158);
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 500;
  margin: 10px;
  border: 1px solid #575656;
  padding: 10px;
}

.parentSection {
  background-color: var(--color-primary) !important;
  /* height: 90vh; */
  overflow-x: auto;
  padding: 10px;
}

.parentSection:hover::-webkit-scrollbar-thumb {
  background: var(--scroll-color) !important;
  transition: 0.4s ease-in-out;

}

.parentSection::-webkit-scrollbar-thumb {
  background: transparent !important;
  transition: 0.4s ease-in-out;

}

.parentSelectionCondition {
  margin: 10px;
}

.parentShowIcon {
  margin: 10px !important;
  cursor: pointer;
  padding: 3px 12px 6px;
  border-radius: 30px;
  background-color: var(--icon-color-primary) !important;
  color: var(--color-primary) !important;
  font-size: 11px;
}

.parentShowIcon:hover {
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.4);
  background-color: rgb(3, 102, 129);
}

.conditionType {
  font-weight: bold;
}

.conditionTitle {
  font-weight: 500;
}

.relationFieldIcon {
  margin: 10px 0px;
  background-color: #333;
  padding: 6px;
}

.popOverContent {
  padding: 15px !important;
}

.showFieldIcon {
  float: right;
  padding: 10px;
}

/* //drag prop */
.dragDropLineArea {
  color: #0078d4;
}

.dragDropLine {
  border-top: 1px solid #0078d4;
}

.draggableTitle {
  height: 140px;
  background-color: var(--color-primary) !important;
  padding-top: 1vh;
}

.draggableDiv {
  background-color: var(--color-primary) !important;
  /* height: 90vh; */
  padding: 0 20px;
  overflow-y: auto;
}

.draggableDiv:hover::-webkit-scrollbar-thumb {
  background: var(--scroll-color) !important;
  transition: 0.4s ease-in-out;

}

.draggableDiv::-webkit-scrollbar-thumb {
  background: transparent !important;
  transition: 0.4s ease-in-out;

}

.buttonDiv {
  height: 10vh;
}

.AdminListBuilerPaper {
  height: 80vh;
}

.CreateNewListBtn {
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  height: 50px;
  margin-bottom: 5px;
}

.CreateNewListBtn:hover {
  background-color: var(--color-prime) !important;
}

.droppableDiv {
  /* height: 90vh; */
  overflow-x: auto;
}

.droppableDiv:hover::-webkit-scrollbar-thumb {
  background: var(--scroll-color) !important;
  transition: 0.4s ease-in-out;

}

.droppableDiv::-webkit-scrollbar-thumb {
  background: transparent !important;
  transition: 0.4s ease-in-out;

}

.droppable {
  margin: 20px;
  background-color: #cecece;
  border: 1px dashed #333;
  border-radius: 10px;
  text-align: center;
  padding-top: 65px;
  color: #575656;
  height: 150px;
}

.droppableIntial {

  margin: 20px;
  background-color: #cecece;
  border: 1px dashed #333;
  border-radius: 10px;
  text-align: center;
  padding-top: 140px;
  color: #575656;
  height: 220px;
}

.droppableparent {

  background-color: #cecece;
  border: 1px dashed #333;
  border-radius: 10px;
  text-align: center;
  padding-top: 50px;
  color: #575656;
  height: 120px;
}

.draggable {
  margin: 5px auto;
  border-radius: 10px !important;
  padding: 15px;
  text-align: center !important;
  /* color: #004256; */
  color: var(--font-color);
  transition-delay: .5s;
}

.draggableSelected {
  background-color: #02ac98 !important;
}

.builderIcon {
  font-size: 2.5em !important;
  color: var(--font-color) !important;
  align-items: center;
}

.builderLable {
  text-align: center;
  text-decoration: none !important;
  font-size: 0.9em;
}

.builderIconDiv {
  margin: auto;
  align-items: center;
  /* height: 100px; */
  width: 50px;
  padding: 10px;
}

.dragArrows {
  color: var(--color-primary) !important;
}

.childSectionBtn {
  padding: 10px;
}

.conditionRenderingDiv {
  margin: 10px;
  padding: 7px;
  border-radius: 10px;
  box-shadow: 1px 3px 6px rgb(0 0 0 / 50%);
}

.addConditionMultiRender {
  text-align: right;
  padding: 10px;
}

/* Preview CSS */
.builderpreview {
  width: 75%;
  margin: 0 auto;
  background: var(--color-primary) !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
  min-height: 300px;
  margin-top: 150px !important;


}

.formCardBackdrop {
  background-color: rgb(0, 130, 114);
  min-height: 50px;

}

.cardHeading-preview {
  font-family: wf_segoe-ui_light, "Segoe UI Light", "Segoe WP Light", "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif;
  font-size: 25px;
  font-weight: bolder;
}

.builderContentPreview {
  padding: 50px;
  padding-top: 0px;


}

.previewFieldCol .ms-Label,
.gridLabel {
  font-size: 18px;
}

.headingpreview {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px !important;
}

.subheadingsPreview {
  font-size: 16px !important;
  font-weight: 500;
}

.previewopt {
  font-size: 15px !important;
  color: #424242;

}

.backButtonDiv {
  margin-bottom: 100px;
  padding: 8px 24px !important;
  float: right;

}

.sigCanvas {
  border: 1px solid #eee !important;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: -65px;

}

.linebreakpreview {
  background-color: #eee !important;
  font-style: italic;

}

.no-padding-right {
  padding-right: 0px !important;
}

.no-padding-left-right {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.buttonwrapper {
  display: inline-block;
}

/* button {
  margin-left: -50%;
  border: none;
  background-color: transparent;
} */

.attachmentInput {
  margin: 0 auto;
  width: 100%;
  border: 1px solid #eee !important;
  /* margin-top: 30px; */
  background-color: var(--color-primary) !important;
  color: var(--font-color) !important;
}

.attachmentInputBar {
  border: none;
  font-size: 16px;
  outline: none;
  margin: 10px;

  background-color: var(--color-primary) !important;
}

.attachmentIcon {
  width: 150px !important;
  float: right;
  margin: 5px;
}

.Fault:hover {
  background-color: rgb(238, 120, 120) !important;
  color: var(--color-primary) !important;
}

.OK:hover {
  background-color: rgb(3, 206, 121) !important;
  color: var(--color-primary) !important;
}

.buttoncss {
  width: 100px;
  margin-left: 1px;
}

.sectionInput {
  width: 50%
}

.attachmentSecIcon {
  width: 50px !important;
  float: right;
  margin: 5px;
}

.dialogstyle {
  align-items: inherit !important;
}

.signInErrorMsg {
  background-color: rgba(202, 15, 15, 0.26);
  color: rgb(82, 4, 4);
  padding: 14px;
  border: 1px solid rgb(184, 10, 10);
  border-radius: 4px;
}

.signInErrorMsgIcons {
  float: right;
  color: rgb(82, 4, 4);
}


/* .listView{
  background-color: var(--color-primary) !important;
} */
.SelectSchedulerCard {
  border: 1px solid #0078d4;
  border-radius: 5px;
  padding: 5px;
}

.cardDivSchedulerUI {
  box-shadow: 1px 1px 10px rgb(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 10px;
}

.schedulerCradIcon {
  color: var(--font-color-light);
  margin: 8px;
  font-size: 18px !important;
}

.schedulerCardTitle {
  font-size: 17px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box !important; */
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  /* width: 240px; */
}

.schedulerCardWO {
  font-size: 12px;
  color: var(--font-color-light);
  margin-bottom: 5px;
}

.schedulerCardSubtitle {
  color: var(--font-color);
  margin-bottom: 4px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
}

.dashBoardCardDetails {
  margin-bottom: 3px;
}

.schedulerGroup {
  width: 33%;
  padding: 5px;
  border: 1px solid #333;
  text-align: center;
  background-color: var(--color-secondary);
}

.borderFirst {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none !important;
}

.borderLast {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none !important;
}

.selectedGroup {
  background-color: #cac9c9;
  color: #333;
  font-weight: 600;

}

.SchedulerViewChangeDiv {
  padding: 10px;
  border: 1px solid var(--color-secondary) !important;
  width: 50%;
  text-align: center;
  color: var(--font-color) !important;
  background-color: var(--color-secondary) !important;
  cursor: pointer;
}

.SchedulerViewSelected {
  background-color: var(--color-primary) !important;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, .5);
  border-radius: 7px;
  cursor: no-drop
}

.mapBtnGroupContainer {
  position: absolute;
  left: 50%;
}

.mapBtnGroup {
  z-index: 10;
  background-color: #eee;
  display: flex;
  /* box-shadow: 5px 5px 11px rgba(0, 0, 0, 0.5); */
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  /* margin-left: auto;
  margin-right: auto; */
  position: relative;
  left: -50%;
  margin-top: 10px;
}

.mapBtnGroup>div {
  padding: 10px;
  cursor: pointer;
}

.mapBtnAssign {
  margin-right: 2px;
}

.mapBtnUnassign {
  margin-left: 2px;
}

.selectedMapBtn {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 0px 6px 2px rgba(0, 0, 0, .5);
}

.zoomOutMapBtn {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 0px 6px 2px rgba(0, 0, 0, .5);
  margin-left: 2px;
}

.statusLegendRow {
  margin-top: 0px;
}


.legendWrapper {
  position: absolute;
  /* left: 20px; */
  z-index: 1;
  margin: 10px;
}

.unassignedPivot {
  margin-top: 40px;
}

.legendDiv {
  padding: 2px 6px;
  margin: 7px;
  border-radius: 10px;
  color: #000;
  font-weight: 500;
  font-size: 12px;
  box-shadow: 1px 0px 4px 2px rgba(0, 0, 0, .4);
}

.cardViewChnageWrapper {
  position: absolute;
  right: 20px;
  z-index: 99;
  margin: 10px;
}

.listOnMapWrapper {
  position: absolute;
  right: 20px;
  z-index: 99;
  margin: 10px;
  width: 28vw;
}

.workorderOnMapList {
  height: 68vh;
  overflow: auto;
}

.workorderOnMapListCard {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--color-secondary) !important;
  box-shadow: 1px 0px 4px 2px rgba(0, 0, 0, .4);

}

.listOnMapWrapperNarrow {
  position: relative;
  z-index: 99;
  /* position: absolute;  
  width: 87%;
  bottom: 0; */
}

.workorderOnMapListNarrow {
  /* height: 20vh;
  overflow: auto; */
  height: 20vh;
  overflow: auto;
  top: 49vh;
  position: absolute;
  width: 100%;

}

.workorderOnMapListCardNarrow {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  margin: 10px;
  padding: 10px;
  padding-left: 5px;
  border-radius: 5px;
  background-color: var(--color-secondary) !important;
  box-shadow: 1px 0px 4px 2px rgba(0, 0, 0, .4);

}

.ColorLegendDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

.ColorLegendItem {
  width: fit-content;
  margin: 5px 5px 10px;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 600;
  color: var(--font-color-inverse) !important;
}

.tooltipTimeText {
  margin: 7px 0;
}

.tooltipUnassignBtn {
  padding: 5px;
}

.tooltipUnassignBtn:hover {
  font-weight: 600;
  color: rgb(125, 191, 253);
}

.toggleViewScheduler {
  background-color: var(--color-secondary) !important;
  border-radius: 20px;
  padding: 5px;
  height: 35px;
  width: 95px;
  display: flex;
}

.toggleViewSchedulerIconDiv {
  border-radius: 20px;
  height: 35px;
  width: 47px;
  cursor: pointer;
}

.toggleViewSchedulerIcon {
  font-size: 18px !important;
  margin: 9px 14px !important;
  color: var(--font-color) !important;
}

.selectedToggleViewSchedulerIconDiv {
  border-radius: 20px;
  height: 35px;
  width: 47px;
  background-color: var(--color-primary) !important;
  box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.5);
}

.iconInverse {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.workingHoursDiv {
  margin-top: 20px;
  background-color: var(--color-secondary) !important;
  border-radius: 20px;
  padding: 10px
}

.workingHoursIcon {
  font-size: 25px;
  margin: 0px 10px;
  color: var(--font-color) !important;
}

.crewTitleColorScheduler {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 7px;
  margin-top: 1px;
}

.schedulerStatusWrapper {
  border-radius: 19px;
  padding: 3px 10px;
  width: fit-content;
}

.schedulerResourceCell {
  font-size: 13px;
  margin: 10px;
}

.schedulerResourceCellText {
  font-weight: 500 !important;
  padding-left: 10px;
}

.schedulerResourceCellWO {
  padding-left: 10px;
  color: #7a7a7a;
  font-weight: 400 !important;

}

.renderDateCellStyle,
.renderTimeCellStyle {
  text-align: center;
  width: 100%;
}

.schedulerSettingBtn {
  margin: 10px !important;
  float: right !important;
}

.dx-popup-content {
  padding: 7px !important;
}

.risk-popup-content {
  padding: 7px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.dx-tooltip-wrapper .dx-overlay-content {
  background-color: var(--color-prime) !important;
}

.dx-scheduler-cell-sizes-vertical,
.dx-scheduler-timeline .dx-scheduler-group-header {
  min-height: 70px !important;
}

.dx-scheduler-timeline.dx-scheduler-work-space-grouped[dx-group-column-count='1'] .dx-scheduler-date-table-scrollable,
.dx-scheduler-timeline.dx-scheduler-work-space-grouped[dx-group-column-count='1'] .dx-scheduler-header-scrollable {
  margin-left: 230px !important;
}

.dx-scheduler-timeline.dx-scheduler-work-space-grouped[dx-group-column-count='1'] .dx-scheduler-group-table,
.dx-scheduler-timeline.dx-scheduler-work-space-grouped[dx-group-column-count='1'] .dx-scheduler-sidebar-scrollable,
.dx-scheduler-timeline.dx-scheduler-work-space-grouped[dx-group-column-count='1'] .dx-scheduler-sidebar-scrollable:before,
.dx-scheduler-timeline .dx-scheduler-group-flex-container .dx-scheduler-group-header,
.dx-scheduler-header-panel-empty-cell {
  width: 230px !important;
}

.dx-scheduler-work-space-month.dx-scheduler-work-space-grouped[dx-group-column-count='1'] .dx-scheduler-date-table-scrollable,
.dx-scheduler-work-space-month.dx-scheduler-work-space-grouped[dx-group-column-count='1'] .dx-scheduler-header-scrollable {
  margin-left: 230px !important;
}

.dx-scheduler-work-space-month.dx-scheduler-work-space-grouped[dx-group-column-count='1'] .dx-scheduler-group-table,
.dx-scheduler-work-space-month.dx-scheduler-work-space-grouped[dx-group-column-count='1'] .dx-scheduler-sidebar-scrollable,
.dx-scheduler-work-space-vertical-grouped[dx-group-column-count="1"] .dx-scheduler-work-space-vertical-group-table,
.dx-scheduler-work-space-month.dx-scheduler-work-space-grouped[dx-group-column-count='1'] .dx-scheduler-sidebar-scrollable:before,
.dx-scheduler-group-flex-container .dx-scheduler-group-row .dx-scheduler-group-header,
.dx-scheduler-timeline .dx-scheduler-group-flex-container .dx-scheduler-group-header,
.dx-scheduler-header-panel-empty-cell {
  width: 230px !important;
}

.dx-item.dx-list-item.dx-state-hover {
  background-color: rgb(230, 227, 227) !important;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  max-height: 450px !important;
  /* height: 400px !important; */
}

.dx-scheduler-appointment-tooltip-wrapper>div.dx-overlay-content {
  max-height: 450px !important;
  /* height: 400px !important; */
}

/* .dx-scrollview-content{
    background-color: var(--color-secondary) !important;
  } */

.dx-scrollview-content>.dx-list-item {
  background-color: var(--color-primary) !important;
  margin-bottom: 1px !important;
}

.dx-scrollview-content .dx-list-group {
  margin-bottom: 1px !important;

}

.dx-scrollview-content>.dx-list-item:last-of-type {
  margin-bottom: 0 !important;
}

.dx-scrollview-content>.dx-list-item:first-of-type {
  margin-top: 0 !important;
}

.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-vertical>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content {
  padding: 0 !important;
}

.dx-list-group .dx-list-item,
.dx-list-group .dx-list-item:first-of-type,
.dx-list-group .dx-list-item:last-of-type {

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
  margin: 10px 5px !important;
  width: 97% !important;
  border-radius: 5px;

}

.scheduler-tooltip-time {
  font-size: 18px;
  color: rgb(54, 166, 247);
  font-weight: 500;
}

.scheduler-tooltip-icon {
  font-size: 17px !important;
  margin: -4px 5px -4px 0;
  color: #6d6d6d !important;

}

.scheduler-tooltip .scheduler-title {
  font-size: 1.5em;
  line-height: 40px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
}

.scheduler-tooltip-subtitle {
  color: #5c5c5c;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
}

.scheduler-tooltip-iconBtnDiv {
  border-top: 1px solid #eee;
  padding-top: 6px;
  margin-top: 5px;
}

.scheduler-tooltip-iconBtn {
  margin-right: 5px !important;
}

.clearButton {
  margin-right: 5px !important;
}

.scheduler-tooltip-iconBtn:hover {
  background-color: #bfe5f7 !important;
}

.external-drop-calendar {
  border-right: 1px solid #ccc;
}

.external-drop-task {
  color: #fff;
  padding: 10px;
  margin: 20px;
  border-radius: 8px;
  font-family: -apple-system, Segoe UI, Roboto, sans-serif;
}

.external-drop-task-blank {
  color: #999;
  border-width: 2px;
  border-style: dotted;
}

.demo-external-drag-drop.demo-wrapper,
.demo-external-drag-drop .mbsc-grid,
.demo-external-drag-drop .mbsc-row,
.demo-external-drag-drop .external-drop-calendar {
  height: 100%;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  padding: 14px;
}

.showtime-preview>div:first-child {
  font-size: 12px;
  white-space: normal;
}

.showtime-preview>div:not(:first-child) {
  font-size: 11px;
  white-space: normal;
}

.scheduler-tooltip .scheduler-info {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  text-align: left;
}

.scheduler-tooltip img {
  height: 80px;
  margin-bottom: 10px;
}

.long-title h3 {
  font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

/* #scroll, #list {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 240px;
} */

.item {
  box-sizing: border-box;
  padding: 10px 20px;
  margin-bottom: 10px;
}

#scheduler {
  margin-left: 270px;
}

.dx-draggable-source {
  opacity: 0.5;
}

.dx-draggable-dragging>* {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.2);
}

.legendText {
  margin-left: 38px;
  margin-top: 12px;
  margin-bottom: 0px;
}

.legendColor {
  width: 30px;
  height: 16px;
  float: left;
  margin-bottom: 0px;
}

.scheduler {
  margin: 0px;
}

.mapBtnGroupContainer {
  position: absolute;
  left: 50%;
}

.mapBtnGroup {
  z-index: 10;
  background-color: var(--color-primary) !important;
  display: flex;
  /* box-shadow: 5px 5px 11px rgba(0, 0, 0, 0.5); */
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  /* margin-left: auto;
  margin-right: auto; */
  position: relative;
  left: -50%;
  margin-top: 10px;
}

.mapBtnGroup>div {
  padding: 10px;
  cursor: pointer;
}

.mapBtnAssign {
  margin-right: 2px;
}

.mapBtnUnassign {
  margin-left: 2px;
}

.selectedMapBtn {
  background-color: var(--color-primary) !important;
  border-radius: 5px;
  box-shadow: 1px 0px 6px 2px rgba(0, 0, 0, .5);
}

.zoomOutMapBtn {
  background-color: var(--color-primary) !important;
  border-radius: 5px;
  box-shadow: 1px 0px 6px 2px rgba(0, 0, 0, .5);
  margin-left: 2px;
}

.statusLegendRow {
  margin-top: 0px;
}

.showAddressBtn {
  margin-top: 21px;
  text-align: center;
  border: 1px solid #ddd;
  padding: 7px;
  cursor: pointer;
}

.natureStripLocationMsg {
  font-size: 11px;
  color: rgb(255, 137, 137);
}

.byline {
  color: dimgrey;
  font-size: 14px;
}

.margin0 {
  margin: 0;
}

.contactTypeButton .dx-button-text {
  text-transform: none;
  font-weight: normal;
}

.dx-resizable-handle {
  color: var(--color-accent) !important;
  width: 10px !important;
}

/* search workoder style */
.searchWOPanelheader {
  background-color: #fff !important;
  color: #4a4a4a !important;
  font-weight: 500;
  font-size: 1.3em;
  border-bottom: 1px solid #eee;
}

.searchWOPanel {
  border: none !important;
  background-color: #f2f2f2 !important;
}

.searchWOPanelItem {
  border-radius: 7px;
  margin-bottom: 15px;
  box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.23);
}

.k-panelbar>.k-item+.k-item {
  border: none !important;
}

/* admin style */

.adminBoxDiv {
  margin: 100px 15px 15px 20px !important;
}

.adminPaperDiv {
  display: flex;
  flex-wrap: wrap;
}

.adminPaper {
  text-align: center;
  cursor: pointer;
  margin: 10px;
  width: 160px;
  padding: 20px;
}

.tcpimage {
  height: 300px;
  width: 100%;
}

.modelCell {
  overflow: hidden !important;
  white-space: nowrap;
}

/* profile */
.ProfileCard {
  margin: auto !important;
  text-align: center !important;
  justify-content: center;
  /* margin: 10px; */
  padding: 10px;
  min-height: 300px;
}

.profileImg {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  text-align: center;
  margin: 20px;
}

.profileeHeading {
  font-size: 44px;
}

.profileSubHeading {
  font-size: 38px;
  text-align: center;
  margin: 10px 0;
}

.profileIcon {
  font-size: 78px !important;
  margin: 10px 0;
}

.profielBtn {
  bottom: 0 !important;
  color: #004256 !important;
}

/* create workorder style */
.assetPaper {
  cursor: pointer;
}

.assetPaper:hover {
  box-shadow: none;
}

.WoCol {
  margin-left: 15px;
}

.AssetIcon {
  font-size: 26px !important;
  /* color: #333; */
  padding: 7px !important;
}

.MultiAssetIcon {
  margin-left: -30px;
  margin-right: 5px;
  vertical-align: middle;
}

.AssetIconDiv {
  margin: auto !important;
  text-align: center !important;
}

.AssetboxTitle {
  font-size: 14px;
}

.AssetLable {
  margin: auto !important;
}

.assetSelectPaper {
  padding: 10px;
}

.assetImagePivot {
  height: 30vh !important;
}

/* schedule work */
.scheduelPaper {
  padding: 15px;
}

.DocumentCardHeading {
  font-size: 18px;
  margin-bottom: 10px;
}

.commandBarButtom {
  background-color: #eee;
  border: none !important;
  font-weight: 600 !important;
}

.commandBarButtomforCreate {
  background-color: #dbdbdb;
  border: none !important;
  font-weight: 600 !important;
}

.commandBarButtomforCreate:hover {
  background-color: #ffffff !important;
}

.popOverContent {
  padding: 15px !important;
}

.showFieldIcon {
  float: right;
  padding: 10px;
}

.addservicereqbtn {
  margin-top: 19px;
  text-align: center;
  border: 1px solid #eee;
  padding: 7px;
  cursor: pointer;
}

.addservicereqbtn:hover {
  background-color: #f2f2f2;

}

.showCrewUserBtn {
  margin-top: 17px;
  text-align: center;
  border: 1px solid #ddd;
  padding-top: 6px;
  cursor: pointer;
}

.showCrewUserBtnDisabled {
  margin-top: 17px;
  text-align: center;
  border: 1px solid #ddd;
  padding-top: 6px;
  cursor: no-drop;
  background-color: #f2f2f2;
}

.showCrewUserBtn:hover {
  background-color: #f2f2f2;
}

.showCrewUserIcon {
  font-size: 26px !important;
}

.activityCard {
  border: 1px solid #eee;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background: white;
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
  margin-bottom: 5px;

}

.activityCardDiv {
  display: flex;
  white-space: nowrap !important;
  flex-wrap: wrap;
}

.activityItem {
  background-color: #eee;
  padding: 4px 8px;
  border-radius: 30;
  margin: 0 5px 0px 2px;
}

.activityItemCancel {
  background-color: rgba(247, 39, 39, 0.507);
  color: #000 !important;
  padding: 4px 8px;
  border-radius: 30;
  margin: 0 5px 0px 2px;
}

.accessDeniedDiv {
  height: 400px;
  width: 500px;
  margin: auto;
  align-items: center;
  margin-top: 15%;
}

.assetSearchWarning {
  background-color: #d6d496;
  color: #000;
  padding: 10px;
}

.serReqTitle {
  font-size: 18px;
  font-weight: 600;
}

.serReqSubTitle {
  margin: 3px;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 16px;
}

.serReqText {
  font-size: 14px;
}

.dataGridIcon {
  font-size: 27px !important;
  cursor: pointer;
  margin: -10px 0 !important;
}

#gridContainer {
  max-width: none !important;
}

.dataGridText {
  font-weight: 400;
}


.dashBoardCardDetails {
  margin-bottom: 3px;
}

.Glimmer {
  bottom: 100%;
  font-size: .66em;
  margin-top: -10px;
  color: "[theme: themePrimary, default: #0078d7]" !important;
}

.GlimmerText {
  padding-left: 6px !important;
}

.schedulerGroup {
  width: 33%;
  padding: 5px;
  border: 1px solid #333;
  text-align: center;
}

.borderFirst {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none !important;
}

.borderLast {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none !important;
}

.selectedGroup {
  background-color: #cac9c9;
  color: #333;
  font-weight: 600;

}

.SchedulerViewChangeDiv {
  padding: 10px;
  border: 1px solid #ddd;
  width: 50%;
  text-align: center;
  background-color: #f2f2f2;
  cursor: pointer;
}

.SchedulerViewSelected {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, .5);
  border-radius: 7px;
  background-color: #fff;
  cursor: no-drop
}

.ColorLegendDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

.ColorLegendItem {
  width: fit-content;
  margin: 5px 5px 10px;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 600;
}

.workTypeListWrapper {
  display: flex;
  flex-flow: wrap;
  margin: 20px 0px;
}

.workTypeListContainer {
  border: 2px solid #eee;
  border-radius: 5px;
  /* background-color: #f8f8f8; */
  padding: 10px;
  /* margin: 10px; */
}

.workTypeListItem {
  background-color: #f0806c;
  border-radius: 30px;
  color: #1b1a1a;
  border: 1px solid #3d7fe9;
  margin: 0 3px;
  padding: 2px 9px;
  cursor: pointer;
  text-transform: none;
  font-size: 12px;
  height: 20px;
  font-weight: bold;
}

.workTypeListItem:hover {
  opacity: 0.7;
}

.validatedworkTypeListItem {
  background-color: #6ad38a;
  border-radius: 30px;
  color: #1b1a1a;
  border: 1px solid #3d7fe9;
  margin: 0 3px;
  padding: 2px 9px;
  /* display: flex; */
  cursor: pointer;
  text-transform: none;
  font-size: 12px;
  height: 20px;
  font-weight: bold;
}

.workTypeListItemTitle {

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  width: 50%;
  height: 25px;
}

/* .adminListBuildAppBar{
    background-color: #0078d4 !important;
  } */
.validateTabPass {
  color: #048821 !important;
}

.validateTabFail {
  color: #be4040 !important;
}

.jss23 .MuiInputBase-input {
  color: black !important;
}

#accordion {
  height: 700px;
}

#accordion h1 {
  font-size: 20px;
}

#accordion h1,
#accordion p {
  margin: 0;
}

#accordion-container {
  margin-right: 400px;
}

.pac-target-input {
  width: 100% !important;
  height: 100% !important;
}

.MuiGrid-spacing-xs-3>.MuiGrid-item {
  padding: 10px !important;

}

.MuiGrid-spacing-xs-3 {
  width: calc(100% + 24px);
  margin: -6px !important;
}

.closeIcon {
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
}

.dx-datagrid-headers {
  position: relative !important;
}

.dx-freespace-row {
  height: 0px !important;
}

.dxDataGrid {
  height: calc(100vh - 140px);
}

.mapwidth {
  width: 95% !important;
}

.icon-buttons-list {
  float: right;
  margin: 100px 10px 0 0;
  color: var(--link-color) !important;
}

.icon-buttons-list button {
  background: #ffffff;
  box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
  border-radius: 20px;
  margin: 0 5px;
}

.iconcolor {
  color: var(--button-primary) !important;
}

.custom-datagrid-toolbar {
  color: var(--button-default) !important;
  background: var(--button-primary) !important;
}

.custom-datagrid-toolbar.dx-state-disabled {
  background: var(--button-default) !important;
}

p.selectAssets {
  font-weight: 600 !important;
}

.tabsDiv.assetSearch .dx-texteditor.dx-editor-filled{
background-color: transparent !important;
}

.tabsDiv.assetSearch .multiSelect-dropdown .dx-texteditor.dx-editor-filled .dx-texteditor-input {
  margin-left: -14px;
}

.tabsDiv.assetSearch .multiSelect-dropdown .dx-editor-filled.dx-texteditor-with-floating-label .dx-texteditor-label {
  margin-left: -14px;
}

.tabsDiv.assetSearch .dx-editor-underlined.dx-texteditor-with-floating-label .dx-texteditor-input{
  padding-bottom: 13px;
} 